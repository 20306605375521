import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Input,
  Alert,
  Select,
  Empty,
  Spin,
  Col,
  Row,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import "antd/dist/antd.css";

const { Option } = Select;

const columns = [
  {
    title: "DATE",
    dataIndex: "Date",
    key: "date",
    width: 150,
  },
  {
    title: "SCHEDULE",
    dataIndex: "Schedule",
    key: "schedule",
    width: 150,
  },
  {
    title: "ROUTE",
    dataIndex: "Route",
    key: "route",
    width: 150,
  },
  {
    title: "VEHICLE",
    dataIndex: "Vehicle",
    key: "vehicle",
    width: 150,
  },

  {
    title: "PROVIDER",
    dataIndex: "Provider",
    key: "provider",
    width: 150,
  },
  {
    title: "DRIVER",
    dataIndex: "Driver",
    key: "driver",
    width: 150,
  },
  {
    title: "AGENT",
    dataIndex: "Agent",
    key: "agent",
    width: 150,
  },
  {
    title: "STUDENTS",
    dataIndex: "Students",
    key: "students",
    width: 150,
  },
];

const MissedTrips = () => {
  const [busy, setBusy] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA") // Ensures 'YYYY-MM-DD' format in local time
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [token, setToken] = useState("");
  const [students, setStudents] = useState([]);
  const [providers, setProviders] = useState([]);
  const [classes, setClasses] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(baseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
    }

    // Fetch filter data from local storage
    const storedStudents = JSON.parse(localStorage.getItem("students")) || [];
    const storedClasses = JSON.parse(localStorage.getItem("classes")) || [];
    const storedRoutes = JSON.parse(localStorage.getItem("routes")) || [];
    const storedProviders = JSON.parse(localStorage.getItem("providers")) || [];
    setStudents(storedStudents);
    setClasses(storedClasses);
    setRoutes(storedRoutes);
    setProviders(storedProviders);
  }, []);

  const calculateTotals = (data) => {
    let totalStudents = 0;
    const uniqueSchedule = new Set();
    const uniqueRoute = new Set();
    const uniqueVehicles = new Set();
    const uniqueProvider = new Set();
    const uniqueDriver = new Set();
    const uniqueAgent = new Set();

    data.forEach((item) => {
      totalStudents += item.Students ? parseInt(item.Students, 10) : 0;

      uniqueSchedule.add(item.Schedule);
      uniqueRoute.add(item.Route);
      uniqueVehicles.add(item.Vehicle);
      uniqueProvider.add(item.Provider);
      uniqueDriver.add(item.Driver);
      uniqueAgent.add(item.Agent);
    });

    return {
      Schedule: `${uniqueSchedule.size} Unique Schedules`,
      Route: `${uniqueRoute.size} Unique Routes`,
      Vehicle: `${uniqueVehicles.size} Unique Vehicles`,
      Provider: `${uniqueProvider.size} Unique Providers`,
      Driver: `${uniqueDriver.size} Unique Drivers`,
      Agent: `${uniqueAgent.size} Unique Agents`,
      Students: totalStudents,
    };
  };

  const generateReport = async (page = currentPage, pageSize = 100) => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    setText("Generating Report...");
    setError("");
    setResults([]);
    try {
      const url = `${baseUrl}/reports/missed-trips?Provider=${selectedProvider}&Route=${selectedRoute}&StartDate=${startDate}&EndDate=${endDate}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { headers });

      const totalRow = calculateTotals(data);
      setResults([...data, totalRow]); // Append the total row at the end
    } catch (error) {
      setError(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>Missed Trips</h4>{" "}
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          <Col span={12}>
            <label for="datePicker" style={{ paddingRight: 10 }}>
              StartDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label for="datePicker" style={{ paddingRight: 10 }}>
              EndDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label for="provider" style={{ paddingRight: 10 }}>
              Provider
            </label>
            <Select
              placeholder="Select Provider"
              size="large"
              style={{ width: "100%" }}
              defaultValue={"All"}
              onChange={(value) => setSelectedProvider(value)}
            >
              {providers.map((provider) => (
                <Option key={provider.id} value={provider.Name}>
                  {provider.Name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <label for="routes" style={{ paddingRight: 10 }}>
              Routes
            </label>
            <Select
              placeholder="Select Route"
              size="large"
              style={{ width: "100%" }}
              defaultValue={"All"}
              onChange={(value) => setSelectedRoute(value)}
            >
              {routes.map((route) => (
                <Option key={route.id} value={route.id}>
                  {route.Name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <Button
              type="primary"
              size="large"
              onClick={generateReport}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>
      <hr />
      {error && <Alert message={error} type="error" showIcon />}
      <Spin spinning={busy}>
        {results.length > 0 ? (
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => exportToExcel(results, "Missed Trips")}
                  style={{ marginRight: 10 }}
                >
                  Export to Excel
                </Button>
                <Button
                  type="primary"
                  onClick={() => exportToCSV(results, "Missed Trips")}
                  style={{ marginRight: 10 }}
                >
                  Export to CSV
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      columns,
                      results,
                      "MissedTrips",
                      "Missed Trips Summary",
                      [0, 175, 80],
                      "/img/go-green.png",
                      startDate,
                      endDate
                    )
                  }
                >
                  Export to PDF
                </Button>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={results}
              scroll={{ x: "max-content" }}
              pagination={{
                current: Number(currentPage),
                pageSize: Number(pageSize),
                total: results.length,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                  generateReport(page, pageSize);
                },
              }}
              // bordered
              // style={{ marginTop: 16, minWidth: 1200 }}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default MissedTrips;
