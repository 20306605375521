import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Input,
  Alert,
  Select,
  Empty,
  Spin,
  Col,
  Row,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import "antd/dist/antd.css";

const { Option } = Select;

const columns = [
  {
    title: "VEHICLE",
    dataIndex: "Vehicle",
    key: "vehicle",
    width: 150,
  },
  {
    title: "DIRECTION",
    dataIndex: "Direction",
    key: "direction",
    width: 150,
  },
  {
    title: "TOTAL TRIPS",
    dataIndex: "TotalTrips",
    key: "totalTrips",
    width: 150,
  },
];

const VehicleTrips = ({ vehicleName: initialVehicleName }) => {
  const [busy, setBusy] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA") //'YYYY-MM-DD' format in local time
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [token, setToken] = useState("");
  const [students, setStudents] = useState([]);
  const [providers, setProviders] = useState([]);
  const [classes, setClasses] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [vehicleName, setVehicleName] = useState(initialVehicleName || "");

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");
    const storedVehicles = JSON.parse(localStorage.getItem("vehicles"));

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
    }
    if (storedVehicles) {
      setVehicles(storedVehicles);
    }
    if (storedVehicles.length > 0) {
      setVehicleName(storedVehicles[0].PlateNo);
    }

    if (BaseUrl) {
      setBaseUrl(baseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
    }

    // Fetch filter data from local storage
    const storedStudents = JSON.parse(localStorage.getItem("students")) || [];
    const storedClasses = JSON.parse(localStorage.getItem("classes")) || [];
    const storedRoutes = JSON.parse(localStorage.getItem("routes")) || [];
    const storedProviders = JSON.parse(localStorage.getItem("providers")) || [];
    setStudents(storedStudents);
    setClasses(storedClasses);
    setRoutes(storedRoutes);
    setProviders(storedProviders);
  }, []);

  const calculateTotals = (data) => {
    let totalTrips = 0;

    const uniqueVehicles = new Set();
    const uniqueDirections = new Set();

    data.forEach((item) => {
      uniqueVehicles.add(item.Vehicle);
      totalTrips += item.TotalTrips || 0;
      uniqueDirections.add(item.Direction);
    });

    return {
      // Adm: "Total",
      Vehicle: `${uniqueVehicles.size} Unique Students`,
      TotalTrips: totalTrips,
      Direction: `${uniqueDirections.size} Unique Directions`,
    };
  };

  const generateReport = async () => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    setText("Generating Report...");
    setError("");
    try {
      const url = `${baseUrl}/reports/summaries/vehicle-trips?Vehicle=${vehicleName}&StartDate=${startDate}&EndDate=${endDate}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { headers });
      console.log(data);

      const totalRow = calculateTotals(data);
      setResults([...data, totalRow]); // Append the total row at the end
    } catch (error) {
      setError(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>Vehicle Trips</h4>{" "}
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          <Col span={12}>
            <label for="datePicker" style={{ paddingRight: 10 }}>
              StartDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label for="datePicker" style={{ paddingRight: 10 }}>
              EndDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <label htmlFor="vehicleSelect" style={{ paddingRight: 10 }}>
              Vehicle
            </label>
            <Select
              id="vehicleSelect"
              value={vehicleName}
              onChange={(value) => setVehicleName(value)}
              placeholder="Select a Vehicle"
              size="large"
              style={{ width: "100%" }}
            >
              {vehicles.map((vehicle) => (
                <Option key={vehicle.id} value={vehicle.PlateNo}>
                  {vehicle.PlateNo}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <Button
              type="primary"
              size="large"
              onClick={generateReport}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>
      <hr />
      {error && <Alert message={error} type="error" showIcon />}
      <Spin spinning={busy}>
        {results.length > 0 ? (
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => exportToExcel(results, "VehicleTrips")}
                  style={{ marginRight: 10 }}
                >
                  Export to Excel
                </Button>
                <Button
                  type="primary"
                  onClick={() => exportToCSV(results, "VehicleTrips")}
                  style={{ marginRight: 10 }}
                >
                  Export to CSV
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      columns,
                      results,
                      "VehicleTrips",
                      "VehicleTrips Activity Summary",
                      [0, 175, 80],
                      "/img/go-green.png",
                      startDate,
                      endDate
                    )
                  }
                >
                  Export to PDF
                </Button>
              </Col>
            </Row>
            <Table
              dataSource={results}
              columns={columns}
              bordered
              scroll={{ x: 1300 }}
              style={{ marginTop: 20 }}
              pagination={{ pageSize: 50 }}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default VehicleTrips;
