import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Input, Select, Empty, Spin, Col, Row } from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
const { Option } = Select;

const TripSummary = ({ onComplete }) => {
  const columns = [
    {
      title: "StartedTrips",
      dataIndex: "StartedTrips",
      key: "StartedTrips",
    },
    {
      title: "ScheduledTrips",
      dataIndex: "ScheduledTrips",
      key: "ScheduledTrips",
    },
    {
      title: "CompletedTrips",
      dataIndex: "CompletedTrips",
      key: "CompletedTrips",
    },
    {
      title: "FailedTrips",
      dataIndex: "FailedTrips",
      key: "FailedTrips",
    },
  ];
  const [busy, setBusy] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [results, setResults] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [token, setToken] = useState("");
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");
    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
    }
    if (BaseUrl) {
      setBaseUrl(baseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
    }
  }, []);

  const generateReport = async (page = currentPage) => {
    setResults([]);
    setBusy(true);
    setText("Fetching data...");

    console.log("Begin data fetch...");

    try {
      const url = `${baseUrl}/reports/summaries/trips?StartDate=${startDate}&EndDate=${endDate}`;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const formattedData = response.data.map((item, index) => ({
        key: `${startDate}-${index}`,
        date: item.Date,
        schedule: item.Schedule,
        route: item.Route,
        vehicle: item.Vehicle,
        provider: item.Provider,
        driver: item.Driver,
        agent: item.Agent,
        students: item.Students,
      }));

      setResults(formattedData);
      setCurrentPage(page);
    } catch (error) {
      setError(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  return (
    <div>
      <h4 style={{ textAlign: "center", marginBottom: 20, marginTop: 20 }}>
        Trip Summary{" "}
      </h4>
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
          <Col span={12}>
            <label for="datePicker" style={{ paddingRight: 10 }}>
              StartDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label for="datePicker" style={{ paddingRight: 10 }}>
              EndDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>

        <Row justify="end">
          <Col>
            <Button
              type="primary"
              size="large"
              onClick={generateReport}
              loading={busy}
              style={{}}
              disabled={!token || busy}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
      </div>

      {/* Table to display results */}
      <Spin spinning={busy}>
        {results.length > 0 ? (
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => exportToExcel(results, "VehicleTripSummary")}
                  style={{ marginRight: 10 }}
                >
                  Export to Excel
                </Button>
                <Button
                  type="primary"
                  onClick={() => exportToCSV(results, "VehicleTripSummary")}
                  style={{ marginRight: 10 }}
                >
                  Export to CSV
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      columns,
                      results,
                      "VehicleTripSummary",
                      `VehicleTrip Summary`,
                      [0, 175, 80]
                    )
                  }
                >
                  Export to PDF
                </Button>
              </Col>
            </Row>

            <Table
              columns={columns}
              dataSource={results}
              scroll={{ x: "max-content" }}
              pagination={{
                current: Number(currentPage),
                pageSize: Number(pageSize),
                total: results.length,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                  generateReport(page, pageSize);
                },
              }}
              bordered
              style={{ marginTop: 16, minWidth: 1200 }}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default TripSummary;
