import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Input,
  Alert,
  Select,
  Empty,
  Spin,
  Col,
  Row,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import "antd/dist/antd.css";

const { Option } = Select;

const columns = [
  {
    title: "ADM",
    dataIndex: "Adm",
    key: "adm",
    width: 150,
  },
  {
    title: "NAME",
    dataIndex: "Name",
    key: "name",
    width: 150,
  },
  {
    title: "CLASS",
    dataIndex: "Class",
    key: "class",
    width: 150,
  },
  {
    title: "STREAM",
    dataIndex: "Stream",
    key: "stream",
    width: 150,
  },
  {
    title: "Guardians",
    dataIndex: "Guardians", // This will hold the concatenated guardian names
    key: "guardians",
    width: 200,
  },
  {
    title: "Schedules",
    dataIndex: "Schedules", // This will hold the concatenated schedule names
    key: "schedules",
    width: 200,
  },
  {
    title: "Routes",
    dataIndex: "Routes",
    key: "routes",
    width: 150,
  },
  {
    title: "GENDER",
    dataIndex: "Gender",
    key: "gender",
    width: 150,
  },
  {
    title: "COST",
    dataIndex: "Cost",
    key: "cost",
    width: 150,
  },
  {
    title: "STATUS",
    dataIndex: "Status",
    key: "status",
    width: 150,
  },
];

const DailyStudentActivity = () => {
  const [busy, setBusy] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  const [token, setToken] = useState("");
  const [students, setStudents] = useState([]);
  const [providers, setProviders] = useState([]);
  const [classes, setClasses] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(baseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
    }

    // Fetch filter data from local storage
    const storedStudents = JSON.parse(localStorage.getItem("students")) || [];
    const storedClasses = JSON.parse(localStorage.getItem("classes")) || [];
    const storedRoutes = JSON.parse(localStorage.getItem("routes")) || [];
    const storedProviders = JSON.parse(localStorage.getItem("providers")) || [];
    setStudents(storedStudents);
    setClasses(storedClasses);
    setRoutes(storedRoutes);
    setProviders(storedProviders);
  }, []);

  const calculateTotals = (data) => {
    let cost = 0;
    const uniqueAdmissions = new Set();
    const uniqueNames = new Set();
    const uniqueClasses = new Set();
    const uniqueRoutes = new Set();
    const uniqueStreams = new Set();
    const uniqueGuardians = new Set();
    const uniqueGender = new Set();
    const uniqueSchedules = new Set();
    const uniqueStatus = new Set();

    data.forEach((item) => {
      cost += item.Cost ? parseFloat(item.Cost) : 0;

      // Add to unique sets for various fields
      uniqueAdmissions.add(item.Adm);
      uniqueNames.add(item.Name);
      uniqueClasses.add(item.Class);
      uniqueStreams.add(item.Stream);
      uniqueGender.add(item.Gender);
      uniqueStatus.add(item.Status);

      // Handle route names safely
      if (item.route && item.route.Name) {
        uniqueRoutes.add(item.route.Name);
      }

      // Handle guardians safely
      if (item.guardians && item.guardians.length > 0) {
        item.guardians.forEach((guardian) =>
          uniqueGuardians.add(guardian.name)
        );
      }

      // Handle schedules safely
      if (item.schedules && item.schedules.length > 0) {
        item.schedules.forEach((schedule) =>
          uniqueSchedules.add(schedule.Direction)
        );
      }
    });

    // Round the total cost to two decimal places
    const roundedCost = Math.round(cost * 100) / 100;

    return {
      Cost: roundedCost,
      Adm: `${uniqueAdmissions.size} Unique Admission Numbers`,
      Name: `${uniqueNames.size} Unique Students`,
      Class: `${uniqueClasses.size} Unique Classes`,
      Routes: `${uniqueRoutes.size} Unique Routes`,
      Gender: `${uniqueGender.size} Unique Genders`,
      Stream: `${uniqueStreams.size} Unique Streams`,
      Guardians: `${uniqueGuardians.size} Unique Guardians`,
      Schedules: `${uniqueSchedules.size} Unique Schedules`,
      Status: `${uniqueStatus.size} Unique Status`,
    };
  };

  const processData = (data) => {
    return data.map((item) => {
      // Extract guardian names and join them into a single string
      const guardians =
        item.guardians && item.guardians.length
          ? item.guardians.map((guardian) => guardian.name).join(", ")
          : "No Guardians";

      // Extract schedule names and join them into a single string
      const schedules =
        item.schedules && item.schedules.length
          ? item.schedules.map((schedule) => schedule.Name).join(", ")
          : "No Schedules";

      // Extract route name safely
      const route =
        item.route && item.route.Name ? item.route.Name : "No Route";

      return {
        ...item,
        Guardians: guardians,
        Schedules: schedules,
        Routes: route,
      };
    });
  };

  const generateReport = async (page = currentPage, pageSize = 100) => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    setText("Generating Report...");
    setError("");
    setResults([]);
    try {
      const url = `${baseUrl}/student`;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const { data } = await axios.get(url, { headers });

      if (data.length === 0) {
        setResults([]);
      } else {
        const processedData = processData(data);
        const totalRow = calculateTotals(processedData);
        setResults([...processedData, totalRow]); // Append the total row at the end
      }
    } catch (error) {
      setError(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>
        Student Daily Activity
      </h4>{" "}
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          <Col span={12}>
            <label for="datePicker" style={{ paddingRight: 10 }}>
              Date
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label for="students" style={{ paddingRight: 10 }}>
              Students
            </label>
            <Select
              placeholder="Select Student"
              defaultValue={"All"}
              size="large"
              style={{ width: "100%" }}
              onChange={(value) => setSelectedStudent(value)}
            >
              {students.map((student) => (
                <Option key={student.id} value={student.Adm}>
                  {student.Name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            <label for="provider" style={{ paddingRight: 10 }}>
              Provider
            </label>
            <Select
              placeholder="Select Provider"
              size="large"
              style={{ width: "100%" }}
              defaultValue={"All"}
              onChange={(value) => setSelectedProvider(value)}
            >
              {providers.map((provider) => (
                <Option key={provider.id} value={provider.Name}>
                  {provider.Name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            <label for="class" style={{ paddingRight: 10 }}>
              Class
            </label>
            <Select
              placeholder="Select Class"
              size="large"
              style={{ width: "100%" }}
              defaultValue={"All"}
              onChange={(value) => setSelectedClass(value)}
            >
              {classes.map((classItem) => (
                <Option key={classItem} value={classItem}>
                  {classItem}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            <label for="routes" style={{ paddingRight: 10 }}>
              Routes
            </label>
            <Select
              placeholder="Select Route"
              size="large"
              style={{ width: "100%" }}
              defaultValue={"All"}
              onChange={(value) => setSelectedRoute(value)}
            >
              {routes.map((route) => (
                <Option key={route.id} value={route.Name}>
                  {route.Name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <Button
              type="primary"
              size="large"
              onClick={generateReport}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>
      <hr />
      {error && <Alert message={error} type="error" showIcon />}
      <Spin spinning={busy}>
        {results.length > 0 ? (
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => exportToExcel(results, "StudentDailyActivity")}
                  style={{ marginRight: 10 }}
                >
                  Export to Excel
                </Button>
                <Button
                  type="primary"
                  onClick={() => exportToCSV(results, "StudentDailyActivity")}
                  style={{ marginRight: 10 }}
                >
                  Export to CSV
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      columns,
                      results,
                      "StudentDailyActivity",
                      "Student Daily Activity Summary",
                      [0, 175, 80]
                    )
                  }
                >
                  Export to PDF
                </Button>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={results}
              scroll={{ x: "max-content" }}
              pagination={{
                current: Number(currentPage),
                pageSize: Number(pageSize),
                total: results.length, // If you have a total count from the API, use that instead
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                  generateReport(page, pageSize); // Fetch the new page data
                },
              }}
              // bordered
              // style={{ marginTop: 16, minWidth: 1200 }}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default DailyStudentActivity;
