import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const exportToExcel = (data, filename = "report") => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};

export const exportToCSV = (data, filename = "report") => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const csv = XLSX.utils.sheet_to_csv(worksheet);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.csv`;
  a.click();
  URL.revokeObjectURL(url);
};

const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };

    img.onerror = () => {
      reject(new Error(`Failed to load image from URL: ${url}`));
    };
  });
};

export const exportToPDF = (
  columns,
  data,
  filename = "report",
  title = "Monthly Vehicle Earnings Report",
  titleColor = [0, 0, 255],
  logoPath = "/img/go-green.png",
  startDate,
  endDate
) => {
  const columnWidth = 80;
  const totalWidth = columns.length * columnWidth;
  const pageWidth = Math.max(totalWidth, 595);

  const doc = new jsPDF({
    orientation: "landscape",
    unit: "pt",
    format: [pageWidth, 842],
  });

  const formatDateTime = (dateStr, time = "00:00:00") => {
    const date = new Date(dateStr);

    // Apply custom time (default is "00:00:00" for startDate, and "23:59:59" for endDate)
    const [hours, minutes, seconds] = time.split(":");
    date.setHours(hours, minutes, seconds);
    return date
      .toLocaleString("en-GB", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      })
      .replace(",", "")
      .replace(/\b\d\b/, "0$&");
  };

  const formattedStartDate = formatDateTime(startDate, "00:00:00");
  const formattedEndDate = formatDateTime(endDate, "23:59:59");

  // Get the current date and time
  const now = new Date();
  const reportDate = now.toLocaleDateString();
  const reportTime = now.toLocaleTimeString();
  const dateTimeString = `Generated on ${reportDate} ${reportTime}`;

  // Convert the logo image to Base64 and then proceed with the PDF generation
  getBase64ImageFromURL(logoPath)
    .then((base64String) => {
      const pageWidthInPoints = doc.internal.pageSize.getWidth();
      const logoX = pageWidthInPoints - 250;
      doc.addImage(base64String, "PNG", logoX, 45, 170, 45);

      doc.setFontSize(26);
      doc.setTextColor(...titleColor);
      doc.text(title, 40, 80);

      // Add the Report Time Period
      doc.setFontSize(14);
      doc.text("Report Time Period From", 40, 120);
      doc.text(formattedStartDate, 220, 120);
      doc.text("To", 420, 120);
      doc.text(formattedEndDate, 440, 120);

      doc.setDrawColor(192);
      doc.line(40, 160, pageWidthInPoints - 40, 160);

      doc.setFontSize(12);
      doc.text(dateTimeString, pageWidthInPoints - 250, 180);

      const tableColumnHeaders = columns.map((col) => col.title);

      const tableData = data.map((row) => {
        return columns.map((col) => {
          if (typeof col.render === "function") {
            return col.render(null, row);
          }
          return row[col.dataIndex];
        });
      });

      // Add the table to the PDF
      doc.autoTable({
        head: [tableColumnHeaders],
        body: tableData,
        theme: "striped",
        startY: 210,
        margin: { horizontal: 20 },
        styles: { overflow: "linebreak" },
        tableWidth: "auto",
        headStyles: { fillColor: [0, 175, 80], textColor: [255, 255, 255] },
      });

      doc.save(`${filename}.pdf`);
    })
    .catch((error) => {
      console.error("Error converting image:", error);
    });
};
